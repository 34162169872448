<template>
  <div></div>
</template>

<script>
import axios from "axios"
import md5 from "js-md5";

export default {
  name: "OAuth",
  methods: {
    issueToken() {
      if (this.$route.hash !== "") {
        const hashParams = {}

        this.$route.hash
            .replace("#", "")
            .split("&")
            .forEach((item) => {
              const params = item.split("=")

              hashParams[params[0]] = params[1]
            })

        if (this.$route.params.issuer === "ok" && "access_token" in hashParams) {
          this.getOkProfile(hashParams.access_token, hashParams.session_secret_key)
        }

        if (this.$route.params.issuer === "google" && "access_token" in hashParams) {
          this.getGoogleProfile(hashParams.access_token)
        }
      }
    },

    async getOkProfile(accessToken, secret) {
      const md5 = require("js-md5")
      const instance = axios.create({baseURL: "https://api.ok.ru"})
      const config = {
        headers: {
          Accept: "application/json"
        },
        params: {
          method: "users.getCurrentUser",
          application_key: "CJJKJGKGDIHBABABA",
          format: "json",
          sig: md5(`method=users.getCurrentUserapplication_key=CJJKJGKGDIHBABABAformat=json${secret}`),
          access_token: accessToken
        }
      }

      const res = await instance.get("/fb.do", config)
      const data = res.data

      const response = await this.$store.dispatch("user/signInSoc", {
        id: data.uid,
        type: "ok"
      })

      if (response.error === 0) {
        this.$router.push("/personal/profile")

        return;
      }

      this.$store.dispatch("bus/fillOAuth", {
        name: data.name,
        email: "",
        phone: "",
        soc: {
          id: data.uid,
          type: "ok"
        },
        birthDate: new Date(data.birthday)
      })

      this.$router.push({
        path: "/",
        query: {
          registerPopup: true
        }
      })
    },

    async getGoogleProfile(accessToken) {
      const instance = axios.create({baseURL: "https://people.googleapis.com"})
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken
        },
        params: {
          personFields: "names,emailAddresses"
        }
      }

      const res = await instance.get("/v1/people/me", config)
      const data = res.data

      const response = await this.$store.dispatch("user/signInSoc", {
        id: data.emailAddresses[0].metadata.source.id,
        type: "google"
      })

      if (response.error === 0) {
        this.$router.push("/personal/profile")

        return;
      }

      this.$store.dispatch("bus/fillOAuth", {
        name: data.names[0].displayName,
        email: data.emailAddresses[0].value,
        phone: "",
        soc: {
          id: data.emailAddresses[0].metadata.source.id,
          type: "google"
        },
        birthDate: null
      })

      this.$router.push({
        path: "/",
        query: {
          registerPopup: true
        }
      })
    }
  },
  mounted() {
    this.issueToken()
  }
}
</script>

<style lang="scss" scoped>

</style>